<template>
    <div class="main">
        <el-scrollbar max-height="750px">
            <el-empty v-if="orgdatas.length <= 0" description="暂无关联组织信息"></el-empty>
            <div v-else v-for="(item, index) in orgdatas" :key="index">
                <el-descriptions :title="`组织` + (index + 1)" :column="2" :size="size" border>
                    <template #extra>
                        <el-popconfirm confirmButtonText="确定" cancelButtonText="取消" icon="el-icon-info" iconColor="red" title="确定删除吗？" @confirm="setOrgDelete(item.Id)">
                            <template #reference>
                                <el-button type="danger" icon="el-icon-delete" size="small">删除</el-button>
                            </template>
                        </el-popconfirm>
                    </template>
                    <el-descriptions-item>
                        <template #label>
                            <i class="el-icon-school"></i>
                            组织
                        </template>
                        {{ item.OrgName }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template #label>
                            <i class="el-icon-paperclip"></i>
                            标签
                        </template>
                        <el-tag size="small" v-if="item.AppTag==0" >消息端（公众号）</el-tag>
                        <el-tag size="small" v-if="item.AppTag==1">管理端（教师端小程序）</el-tag>
                        <el-tag size="small" v-if="item.AppTag==2">用户端（家长端小程序）</el-tag>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template #label>
                            <i class="el-icon-document"></i>
                            备注
                        </template>
                        {{ item.RemarkInfo }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template #label>
                            <i class="el-icon-time"></i>
                            关联时间
                        </template>
                        {{ item.UpdatedTime }}
                    </el-descriptions-item>
                </el-descriptions>
                <el-divider />
            </div>
        </el-scrollbar>
        <el-divider>
            <el-button type="primary" icon="el-icon-plus" @click="addSetOrg">关联组织</el-button>
        </el-divider>
        <el-dialog :title="dialogTitle" v-model="dialogVisible" width="30%" center>
            <el-form :model="setOrgFrom" ref="setOrgFrom" label-width="100px">
                <div v-for="(item, index) in setOrgFromConfig" :key="index">
                    <el-form-item :label="item.label" v-if="item.isInput != false" :rules="item.rules" :prop="item.attr">
                        <el-select v-if="item.type == 'enum'" v-model="setOrgFrom[item.attr]" :placeholder="item.placeholder" filterable>
                            <el-option v-for="ite in item.dic" :key="ite.value" :label="ite.title" :value="ite.value">
                            </el-option>
                        </el-select>
                         <el-input
                            v-else-if="item.type == 'textarea'"
                            type="textarea"
                            v-model="setOrgFrom[item.attr]"
                            :placeholder="item.placeholder"
                          ></el-input>
                        <el-input v-else v-model="setOrgFrom[item.attr]" :placeholder="item.placeholder" :disabled="disabled"></el-input>
                    </el-form-item>
                </div>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="saveSetOrg('setOrgFrom')">保存</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script>
let _this;
export default {
    name: "setOrg",
    props: {
        configId: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            orgdatas: [],
            dialogTitle: "",
            dialogVisible: false,
            disabled: false,
            setOrgFrom: {
                Id: 0,
                configId: 0,
                OrgID: "",
                AppTag: 0,
                RemarkInfo: "",
            },
            setOrgFromConfig:[]
        };
    },
    mounted() {
        _this=this;
        _this.initsetOrgdatas();
    },
    methods: {
        initsetOrgdatas() {
            
            if (_this.$props.configId != 0) {
                _this.$API.WeChatConfigSysOrg.GetWeChatConfigSysOrgs.get(
                    _this.$props.configId
                ).then((res) => {
                    _this.orgdatas = res;
                });
            }
        },
        GetSchoolOrgList() {
            var data = [];
            this.$API.WeChatConfigSysOrg.GetSchoolOrgList.get(_this.$props.configId).then((res) => {
                if (res) {
                    res.forEach((it) => {
                        data.push({ value: it.Id, title: it.OrgName });
                    });
                }
            });
            return data;
        },
        setOrgDelete(id) {
            _this.$API.WeChatConfigSysOrg.FakeDelete.delete([id]).then((res) => {
                if (res.Success) {
                    _this.$message.success(res.Message);
                    _this.initsetOrgdatas();
                }
            });
        },
        addSetOrg() {
            _this.dialogVisible = true;
            _this.disabled = false;
            _this.dialogTitle = "关联组织";
            _this.setOrgFrom = {
                 Id: 0,
                configId: _this.$props.configId,
                OrgID: "",
                AppTag: 0,
                RemarkInfo: "",
            };
            _this.setOrgFromConfig= [
                {
                    label: "组织",
                    attr: "OrgID",
                    placeholder: "请选择组织",
                    rules: [
                        {
                            required: true,
                            message: "请选择组织!",
                            trigger: "change",
                        },
                    ],
                    type: "enum",
                    dic: this.GetSchoolOrgList(),
                },
                {
                    label: "应用标签",
                    attr: "AppTag",
                    placeholder: "请选择应用标签",
                    rules: [
                        {
                            required: true,
                            message: "请选择应用标签!",
                            trigger: "change",
                        },
                    ],
                    type: "enum",
                    dic: [
                        { value: 0, title: "消息端（公众号）" },
                        { value: 1, title: "管理端（教师端小程序）" },
                        { value: 2, title: "用户端（家长端小程序）" }
                    ],
                },
                {
                   label: "备注",
                   attr: "RemarkInfo",
                   placeholder: "请输入备注（长度不大于100个字符）",
                   rules: [
                     {
                       min: 0,
                       max: 100,
                       message: "长度不大于100个字符",
                       trigger: "blur",
                     },
                   ],
                   type: "textarea",
                 },
            ]
        },
        saveSetOrg(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    _this.$API.WeChatConfigSysOrg.AddOrUpdate.post(
                        _this.setOrgFrom
                    ).then((res) => {
                        _this.dialogVisible = false;
                        if (res.Success) {
                            _this.$message.success(res.Message);
                            _this.initsetOrgdatas();
                        }
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
    },
};
</script>

<style>
.main {
    margin: 20px;
}
</style>