<template>
	<div class="page">
		<el-main>
			<el-container>
				<el-row>
					<el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
						<el-form :inline="true">
							<el-input v-model="keyword" placeholder="请输入关键字查询" class="input-with-select" style="width: 300px; margin-right: 10px" size="medium">
								<template #prepend>
									<el-select v-model="checkInput" multiple collapse-tags placeholder="请选择" style="width: 120px">
										<el-option v-for="(item, i) in dataTableSearch" :key="i" :label="item.label" :value="item.attr"></el-option>
									</el-select>
								</template>
							</el-input>
							<slot name="searchSlot"></slot>
							<el-form-item>
								<el-button type="primary" icon="el-icon-search" size="medium" @click="SearchPage()">查询</el-button>
								<el-button type="primary" v-has="'Add'" icon="el-icon-plus" size="medium" @click="configAdd">新增配置</el-button>
								<el-button type="danger" v-has="'FakeDelete'" icon="el-icon-delete" size="medium" @click="DeleteConfig()">删除</el-button>
							</el-form-item>
							<el-form-item>
								<el-popover placement="bottom" :width="400" trigger="click">
									<template #reference>
										<el-button size="medium"><i class="el-icon-arrow-down el-icon-menu" /> 列筛选</el-button>
									</template>
									<el-checkbox-group v-model="dataTableSearchList">
										<div v-for="(item, i) in dataTableList" :key="i">
											<el-checkbox v-if="item.visible != false" :label="item" checked style="display: block; margin: 10px" @change="filterFunHandle('filter', item.attr, i)"> {{ item.label }}</el-checkbox>
											<el-checkbox v-else :label="item" style="display: block; margin: 10px" @change="filterFunHandle('filter', item.attr, i)"> {{ item.label }}</el-checkbox>
										</div>
									</el-checkbox-group>
									<el-button size="small" type="text" @click="filterFunHandle('allchecked')">全选</el-button>
									<el-button size="small" type="text" @click="filterFunHandle('cancel')">取消全选</el-button>
								</el-popover>
							</el-form-item>
						</el-form>
					</el-col>
					<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
						<el-table size="small" border ref="multipleTable" :data="dataTable" style="width: 99%" max-height="700" :height="tableHeight" :fit="true" @selection-change="handleSelectionChange">
							<el-table-column type="selection" fixed="left" header-align="center" align="center"></el-table-column>
							<el-table-column label="操作" fixed="right" header-align="center" align="center" width="400">
								<template #default="scope">
									<el-button size="mini" v-has="'Update'" @click="setOrg(scope.row.Id, scope.row.AppName)">组织配置</el-button>
									<el-button size="mini" v-if="scope.row.AppType == 0" v-has="'Update'" @click="TemplateMsgConfig(scope.row.AppID, scope.row.AppName)">模板消息配置</el-button>
									<el-button size="mini" v-has="'Update'" @click="configEdit(scope.row.Id)">修改</el-button>
									<el-popconfirm confirmButtonText="确定" cancelButtonText="取消" icon="el-icon-info" iconColor="red" title="确定删除吗？" @confirm="configDelete(scope.row.Id)">
										<template #reference>
											<el-button type="danger" v-has="'FakeDelete'" size="mini">删除</el-button>
										</template>
									</el-popconfirm>
								</template>
							</el-table-column>
							<el-table-column v-for="(col, index) in dataTableSearchList" :key="index" sortable header-align="center" align="center" :prop="col.attr" :label="col.label">
								<template #default="scope">
									<el-image v-if="col.type == 'img'" :src="scope.row[col.attr]" :preview-src-list="[scope.row[col.attr]]" fit="fill" style="width: 40px; height: 40px"></el-image>
									<el-tag size="medium" v-else-if="col.type == 'enum'">
										{{ col.formatters(scope.row[col.attr]) }}
									</el-tag>
									<div v-else-if="col.type == 'custom'">
										<el-tag size="medium" v-if="scope.row[col.attr] == '0'" type="success">
											正常
										</el-tag>
										<el-tag size="medium" type="warning" v-else-if="scope.row[col.attr] == '1'">
											停用
										</el-tag>
										<el-tag size="medium" type="danger" v-else> 已删除 </el-tag>
									</div>
									<label v-else>{{ scope.row[col.attr] }}</label>
								</template>
							</el-table-column>
						</el-table>
					</el-col>
					<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
						<paging :currentPage="currentPage" :pagesize="pageSize" :total="total" @sizeChange="handleSizeChange" @currentChange="handleCurrentChange" :pagesizes="pageSizes" />
					</el-col>
				</el-row>
			</el-container>
		</el-main>
		<el-dialog v-model="dialogVisible" :title="dialogTitle" width="30%" :before-close="handleClose" destroy-on-close center>
			<saveWechatconfig @operationSuccess="operationSuccess" :configId="configId" />
		</el-dialog>

		<el-drawer v-model="setOrgVisible" direction="rtl" size="30%" :before-close="handleClose" destroy-on-close>
			<slot name="title">
				<el-divider>
					<h2>
						<span class="drawerTitle">{{ setOrgDrawerTitle }}</span
						>关联组织信息
					</h2>
				</el-divider></slot
			>
			<setOrg @operationSuccess="operationSuccess" :configId="configId" />
		</el-drawer>

		<el-dialog v-model="dialogTemplateMsgVisible" :title="dialogTemplateMsgTitle" width="70%" :before-close="handleClose" destroy-on-close center>
			<templateMsg @operationSuccess="operationSuccess" :appId="appId" />
		</el-dialog>
	</div>
</template>
<script>
import saveWechatconfig from "./saveWechatconfig";
import setOrg from "./setOrg";
import templateMsg from "./templateMsg";
export default {
	name: "wechatconfig",
	components: {
		saveWechatconfig,
		setOrg,
		templateMsg,
	},
	data() {
		return {
			configId: 0,
			dialogTitle: "",
			dialogVisible: false, //创建修改配置
			setOrgVisible: false, //是否显示关联组织管理
			setOrgDrawerTitle: "", //关联组织管理标题
			appId: "",
			dialogTemplateMsgVisible: false, //是否显示模板消息配置管理
			dialogTemplateMsgTitle: "", //模板消息配置管理标题
			total: 0, //数据总条数
			pageSize: 10, //当前显示数据数量
			pageSizes: [10, 20, 50, 100],
			currentPage: 1, //分页当前页
			keyword: "", //搜索输入关键字
			checkInput: "", //选择所需要查询的内容
			dataConfig: [
				{
					label: "Id",
					attr: "Id",
					isInput: false,
					isDisplay: false,
				},
				{
					label: "应用名称",
					attr: "AppName",
					isSearch: true,
				},
				{
					label: "应用类型",
					attr: "AppType",
					type: "enum",
					formatters: function(val) {
						var result;
						switch (val) {
							case 1:
								result = "小程序";
								break;
							default:
								result = "公众号";
								break;
						}
						return result;
					},
				},
				{
					label: "AppID",
					attr: "AppID",
					isSearch: true,
				},
				{
					label: "原始ID",
					attr: "GID",
					isSearch: true,
				},
				{
					label: "密匙",
					attr: "AppSecret",
					isSearch: true,
				},
				{
					label: "状态",
					attr: "Status",
					type: "custom",
				},
				{
					label: "备注",
					attr: "RemarkInfo",
					visible: false,
				},
				{
					label: "创建时间",
					attr: "CreatedTime",
					visible: false,
				},
				{
					label: "更新时间",
					attr: "UpdatedTime",
					visible: false,
				},
			],
			dataTableSearch: [], //搜索信息
			dataTableList: [], //显示列信息
			dataTableSearchList: [], //列筛选信息
			dataTable: [], //显示数据
			multipleSelection: [], //选中数据
			tableHeight: 200, //table高度
		};
	},
	mounted() {
		const that = this;
		that.$nextTick(function() {
			// 仅在整个视图都被渲染之后才会运行的代码
			//设置table位置
			let heightStyle = window.innerHeight - 230;
			that.tableHeight = heightStyle;

			//窗口大小改变时，table 高度设置
			window.onresize = () => {
				return (() => {
					let heightStyle = window.innerHeight - 230;
					that.tableHeight = heightStyle;
				})();
			};
		});
		that.SearchPage();
		that.dataTableSearch = that.dataConfig.filter((it) => it.isSearch == true); //可搜索字段
		that.dataTableList = that.dataConfig.filter((it) => it.isDisplay != false); //可显示列
	},
	methods: {
		//页面搜索查询
		SearchPage() {
			let that = this;
			let json = {
				searchValue: "",
				pageNo: that.currentPage,
				pageSize: that.pageSize,
				searchBeginTime: that.searchStartTime,
				searchEndTime: that.searchEndTime,
				sortField: "",
				sortOrder: "",
				descStr: "",
			};
			if (that.checkInput.length > 0 && that.keyword != "") {
				var searchParameters = [],
					input = that.keyword;
				for (let index = 0; index < that.checkInput.length; index++) {
					const element = that.checkInput[index];
					var searchParametersItem = {
						field: element,
						op: 8,
						value: input,
						orGroup: "",
					};
					searchParameters.push(searchParametersItem);
				}
				json.searchParameters = searchParameters;
			}

			that.$API.WechatConfig.QueryByPage.post(json).then((res) => {
				if (res) {
					that.total = res.TotalRows;
					that.pagesize = res.PageSize;
					that.currentPage = res.PageNo;
					that.dataTable = res.Rows;
				}
			});
		},
		handleSizeChange(val) {
			this.pageSize = val;
			this.SearchPage();
		},
		handleCurrentChange(val) {
			this.currentPage = val;
			this.SearchPage();
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		// 列筛选
		filterFunHandle(type, currentItem, index) {
			if (type === "allchecked") {
				// 全选
				this.dataTableSearchList = this.dataConfig.filter((it) => it.isDisplay != false);
			} else if (type === "cancel") {
				// 取消全选
				this.dataTableSearchList = []; // 复选框置为空，全部不选择
			}
		},
		configAdd() {
			this.configId = 0;
			this.dialogTitle = "新增配置";
			this.dialogVisible = true;
		},
		configEdit(id) {
			this.configId = id;
			this.dialogTitle = "修改配置";
			this.dialogVisible = true;
		},
		DeleteConfig() {
			var that = this;
			if (!that.multipleSelection || that.multipleSelection.length <= 0) {
				that.$message.warning("请选择需要删除的配置！");
				return;
			}
			var ids = new Array();
			var msgStr = "";
			that.multipleSelection.forEach((it) => {
				ids.push(it.Id);
				msgStr += `【${it.AppName}】`;
			});
			that
				.$confirm(`确认删除${msgStr}等${that.multipleSelection.length}条配置信息吗？`)
				.then(() => {
					that.$API.WechatConfig.FakeDelete.delete(ids).then((res) => {
						if (res.Success) {
							that.$message.success(res.Message);
							that.SearchPage();
						}
					});
				})
				.catch(() => {});
		},
		configDelete(id) {
			var that = this;
			that.$API.WechatConfig.FakeDelete.delete([id]).then((res) => {
				if (res.Success) {
					that.$message.success(res.Message);
					that.SearchPage();
				}
			});
		},
		operationSuccess() {
			//操作成功后，关闭弹出框，刷新数据显示
			this.dialogVisible = false;
			this.configId = 0;
			this.SearchPage();
		},
		//关联组织信息
		setOrg(id, appName) {
			this.configId = id;
			this.setOrgVisible = true;
			this.setOrgDrawerTitle = `${appName}`;
		},
		TemplateMsgConfig(appid, appName) {
			this.appId = appid;
			this.dialogTemplateMsgVisible = true;
			this.dialogTemplateMsgTitle = `【${appName}】模板消息配置`;
		},
		handleClose(done) {
			this.$confirm("确认关闭？")
				.then(() => {
					done();
				})
				.catch(() => {});
		},
	},
};
</script>
<style scoped>
.page {
	margin: 5px;
	padding: 20px;
	min-height: 840px;
	width: 100%;
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.el-pagination .btn-next .el-icon,
.el-pagination .btn-prev .el-icon {
	width: auto;
}
.drawerTitle {
	color: #409eff;
}
</style>
